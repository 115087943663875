
//Banner
import BannerImage from "../assets/images/OneFifty_Contact/Banner.png"

//Components
import Form from "../components/Form";
import Banner from "../components/Hero";
import Footer from "../components/Footer";
import QuickNav from "../components/QuickNav";

//React Imports
import { Helmet } from "react-helmet";

let QuickLinks = {
  backgroundColor: "cyan",
  prev: {
    title: "Neighborhood",
    url: "/neighborhood"
  },
  next: {
    title: "Home",
    url: "/"
  }
}
let footerData = {
  backgroundColor: "green"
}
let BannerData = {
  backgroundImage: BannerImage,
  backgroundColor: "normal",
  heading: "You Deserve <span>the</span> Best",
  description: "Ready for a change? Use the contact form below and click “submit” to receive more information about renting an apartment at One Fifty and starting your next chapter in Bushwick, Brooklyn. ",
  color: "green",
}

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us | One Fifty Brooklyn Apartments</title>
      </Helmet>
      <Banner data={BannerData} />
      <Form />
      <QuickNav links={QuickLinks} />
      <Footer data={footerData} />
    </>
  );
} 