import { Link } from "react-router-dom";
import React from "react";

const Banner = ({ data }) => {
  const {
    backgroundImage,
    heading,
    description,
    buttonUrl,
    buttonText,
    bannerClass,
    color,
    mobileImage,
  } = data;
  return (
    <>
      <div className={"banner " + color}>
        <img
          src={backgroundImage}
          alt=""
          className={
            mobileImage
              ? "background-image mobile-disabled"
              : "background-image"
          }
        />
        {mobileImage && (
          <img src={mobileImage} className="mobile-background-image" />
        )}
        <div className="inner">
          {(heading || description) && (
            <div className="content">
              <h1
                className="heading"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              <p className="description">{description}</p>
              {buttonUrl && buttonText && <Link to="/">Back to Home</Link>}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Banner;
